import { CSSValue } from '../interfaces/css-value.type';

export const defaultRoundedButtonBorderRadius: CSSValue = {
  value: 9999,
  unit: 'px'
};
export enum StorageKeyName {
  loginRequest = 'loginRequest',
  Token = 'Token',
  name = 'name',
  companyId = 'companyId',
  

}

