import { VexRoutes } from "src/@vex/interfaces/vex-route.interface";
import { CustomLayoutComponent } from "../custom-layout/custom-layout.component";

export const AppRoutes: VexRoutes = [
  {
    path: "",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("src/app/auth/auth.module").then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: "Booking",
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "src/app/online-booking-admin/online-booking-admin.module"
          ).then((m) => m.OnlineBookingAdminModule),
      },
    ],
  },
];
