import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from "@angular/core";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import {
  MatIconRegistry,
  SafeResourceUrlWithIconOptions,
} from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { SplashScreenService } from "src/@vex/services/splash-screen.service";
import { NavigationService } from "src/@vex/services/navigation.service";
import { LoginResponse, OidcSecurityService } from "angular-auth-oidc-client";
import { AuthService } from "./pages/pages/auth/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private route: Router,
    private renderer: Renderer2,
    private platform: Platform,
    private navigationService: NavigationService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    public oidcSecurityService: OidcSecurityService
  ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case "mat":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case "logo":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case "flag":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    this.navigationService.items = [
      {
        type: "link",
        label: "Dashboards",
        route: "/Booking",
      },
      {
        type: "link",
        label: "Slot",
        route: "slot",
      },
      {
        type: "link",
        label: "Service",
        route: "service",
      },
      {
        type: "link",
        label: "Locations",
        route: "location",
      },
      {
        type: "link",
        label: "Booking",
        route: "booking",
      },
      {
        type: "link",
        label: "Payment",
        route: "payment",
      },
      {
        type: "link",
        label: "User",
        route: "user",
      },
      {
        type: "link",
        label: "Vehicle",
        route: "vehicle",
      },
      {
        type: "link",
        label: "Criteria",
        route: "criteria",
      },
    ];
  }

  ngOnInit(): void {
    this.oidcSecurityService
      .checkAuth()
      .subscribe((loginResponse: LoginResponse) => {
        console.log(loginResponse);

        const { isAuthenticated, userData, accessToken, idToken, configId } =
          loginResponse;
        this.auth.Token = accessToken;
        this.auth.userName = userData.name;
        this.auth.companyId = userData.dealer;

        if (isAuthenticated) {
          if (
            window.location.pathname == "" ||
            window.location.pathname == "/" || window.location.pathname=="/Booking"
          ) {
            
            this.route.navigate(["/Booking"]);
          }
        } else {
          this.oidcSecurityService.authorize();
        }
      });
  }
}
