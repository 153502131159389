import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, map, of } from "rxjs";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { RestApiService } from "src/app/core/service/rest-api.service";
import { StorageKeyName } from "src/@vex/config/constants";

@Injectable({
  providedIn: "root",
})
export class AuthService extends RestApiService {
  user = new Subject<any>();

  private reloadRequested = new Subject<boolean>();

  reload$ = this.reloadRequested.asObservable();

  triggerReload() {
    this.reloadRequested.next(true);
  }

  public set LoginRequest(value) {
    localStorage.setItem(StorageKeyName.loginRequest, value);
  }
  public get LoginRequest(): string {
    return localStorage.getItem(StorageKeyName.loginRequest);
  }
  public set companyId(value) {
    localStorage.setItem(StorageKeyName.companyId, value);
  }
  public get companyId(): string {
    return localStorage.getItem(StorageKeyName.companyId);
  }
  public set Token(value) {
    localStorage.setItem(StorageKeyName.Token, value);
  }
  public get Token(): string {
    return localStorage.getItem(StorageKeyName.Token);
  }
  public set userName(value) {
    localStorage.setItem(StorageKeyName.name, value);
  }
  public get userName(): string {
    return localStorage.getItem(StorageKeyName.name);
  }

  constructor(public override httpClient: HttpClient) {
    super(httpClient);
  }
}
