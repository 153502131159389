import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export abstract class RestApiService {
    constructor(
        public httpClient: HttpClient
    ) { }

    protected get<T>(url: string): Observable<T> {
        return this.httpClient.get<T>(`${environment.origin}/api/${url}`);
    }

    protected post<T>(url: string, data: any): Observable<T> {
        return this.httpClient.post<T>(`${environment.origin}/api/${url}`, data);
    }

    protected put<T>(url: string, data: any): Observable<T> {
        return this.httpClient.put<T>(`${environment.origin}/api/${url}`, data);
    }

    protected delete<T>(url: string): Observable<T> {
        return this.httpClient.delete<T>(`${environment.origin}/api/${url}`);
    }

    protected upload(url: string, formData: any): Observable<any> {
        return this.httpClient.post(`${environment.origin}/api/${url}`, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    protected downloadPdf(url: string): Observable<any> {
        return this.httpClient.get(`${environment.origin}/api/${url}`, { responseType: 'blob' });
    }
}
