import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    public oidcSecurityService: OidcSecurityService
  ) { }

  Init() {
    return new Promise<void>((resolve, reject) => {
      const hasLoginRequest = localStorage.getItem("hasLoginRequest");
      if (!Boolean(hasLoginRequest)) {
        this.oidcSecurityService.authorize();
        localStorage.setItem("hasLoginRequest", "true");
      }
      resolve();
    });
  }
}
