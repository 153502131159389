import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';


@NgModule({
    imports: [AuthModule.forRoot({
        config: {
            authority: 'https://authstsidentityui.azurewebsites.net',
            redirectUrl: window.location.origin+'/Booking',
            postLogoutRedirectUri:'http://localhost:4200' ,
            clientId: 'Booking-Admin',
            scope: 'profile Booking-Admin-API openid', // 'openid profile offline_access ' + your scopes
            responseType: 'code',
            silentRenew: true,
            useRefreshToken: true,
          
        }
    })],
    exports: [AuthModule],
})
export class AuthConfigModule { }
